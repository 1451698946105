import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const Home = () => import('../views/index.vue')
// const RoadDemo = () => import('../views/roadDemo.vue')


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/subpage',
    name: 'subpage',
    component: () => import('@/views/subpage.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/business.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
