<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    width: 100%;
  }

  html,body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  h3,h2,p{
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  a {
    color: #42b983;
  }

  .el-select-dropdown {
    margin-top: 5px !important;
    background: rgba(0,0,0,.7) !important;
    border: none !important;
    .popper__arrow:after {
      display: none !important;
    }
    .el-select-dropdown__item {
      color: #fff !important;
    }
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
        background: #2b2d3d;
    }
  }

  ::-webkit-scrollbar {/*滚动条整体样式*/
    width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #082C52;
  }
  ::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #010F1A;
  }

  ::-webkit-input-placeholder {
    color: #fff;
    font-size: 13px;
  }
  ::-moz-placeholder {
    color: #fff;
    font-size: 13px;
  }
  :-ms-input-placeholder {
    color: #fff;
    font-size: 13px;
  }
  
  .el-popper .popper__arrow {
    display: none !important;
  }

</style>
