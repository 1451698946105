import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import Axios from 'axios';
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import '../node_modules/swiper/dist/css/swiper.css'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)  


import VueAMap from 'vue-amap'
VueAMap.initAMapApiLoader({
  key: '0e3bdbf77f970b32d4f14e3144ab8550', //高德的key（自己申请）
  uiVersion: '1.1',//UI库 版本
  v: '2.0', //高德SDK 版本
});
Vue.use(VueAMap)



Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$axios = Axios

Axios.defaults.baseURL = '/api/'


new Vue({
  beforeCreate(){
    Vue.prototype.$bus = this
  },
  router,
  render: h => h(App),
}).$mount('#app')
